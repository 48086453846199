.header {
  text-align: center;
}
.result-page-wrapper {
  display: grid;
  grid-template-columns: 250px 1fr;
  gap: 50px;
}

.result-list-wrapper {
  flex: 1;
}
